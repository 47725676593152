@import '~antd/dist/antd.css';

a:hover {
    color: #f9faff;
}

.ant-message-notice-content {
    background-color: #262626;
    color: #e3e3e3;
}

.ant-btn-primary {
    background-color: #1da57a;
    border-color: #1da57a;
}
